<template>
  <v-container fluid class="pa-0 ma-0">
    <v-card v-if="linkIsNotExist" width="40%" flat style="margin-left:30%; margin-right:30%; margin-top:5%;">
      <v-card-title style="text-align:center; display:block;">
        <h1>
          Link does not exist
        </h1>
      </v-card-title>
      <v-card-subtitle style="text-align:center">
        <h4>
          The URL you clicked was obsolete or never existed.
        </h4>
      </v-card-subtitle>
      <img style="margin:auto;  display:block;" src="/linkNotFound.png" width="50%">
      <v-card-subtitle style="text-align:center; display:block;">
        <h2><a href="https://documentsearch.hrd-s.com/">Go back to Document Search System</a></h2>
      </v-card-subtitle>

    </v-card>
    <v-layout align-center justify-center v-else-if="loading" pt-5>
      <div class="text-xs-center">
        <a-icon type="sync" spin :style="{ fontSize: '60px', color: '#08c' }" />
      </div>
    </v-layout>
    <template v-else>
      <v-toolbar style="background-color: #f6f5f5" flat>
        <v-tooltip bottom color="black" nudge-top="7">
          <template v-slot:activator="{ on }">
            <v-btn
              dark
              fab
              x-small
              rounded
              v-on="on"
              class="mr-2"
              color="#1565c0"
              @click="CloseTab()"
            >
              <v-icon>mdi-arrow-left</v-icon>
            </v-btn>
          </template>
          <span>Back</span>
        </v-tooltip>
        <v-toolbar-title>{{ viewedRule._id }}</v-toolbar-title>
        {{ title_spacer }}

        <v-btn rounded @click="print()" color="success" class="ml-1" small>
          <v-icon color="#455A64">mdi-printer</v-icon>Print
        </v-btn>
        <!-- <v-btn @click="PDFSrc()" color="success" class="ml-1">
            <v-icon color="#B71C1C">mdi-pdf-box</v-icon>Download
          </v-btn> -->
        <v-btn
          rounded
          small
          color="success"
          @click="
            isFavorite = !isFavorite;
            addFavorite();
          "
          class="ml-1"
        >
          <v-icon :color="isFavorite ? 'yellow' : 'white'">{{ isFav }}</v-icon>
          <span>Favorite </span>
        </v-btn>
        <v-btn
          rounded
          small
          color="success"
          @click="goToFeedback()"
          class="ml-1"
        >
          <v-icon>mdi-comment-quote</v-icon><span>Feedback </span>
        </v-btn>
        <!-- <v-btn rounded color="success" @click="viewRulebookHistory(viewedRule._id)" class="ml-1">
          <v-icon>mdi-history</v-icon><span>History </span>
        </v-btn> -->
        <v-spacer></v-spacer>

        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <v-btn
              v-on="on"
              icon
              color="primary"
              class="mr-2"
              @click="viewHistory($route.params.id)"
              ><v-icon>mdi-history</v-icon></v-btn
            >
          </template>
          <span>View History</span>
        </v-tooltip>
        <v-icon color="#ffa62b">mdi-star</v-icon>
        <span> {{ isFavCnt }}</span>
        <v-btn
          v-if="userInfo.name == 'ESTEBAN, JOAN R.'"
          rounded
          color="error"
          @click="obsolete(viewedRule._id)"
          class="ml-1"
        >
          <v-icon>mdi-trash-can</v-icon><span>Obsolete </span>
        </v-btn>
      </v-toolbar>
      <!-- {{screenSize}} -->
      <v-card class="ma-1" flat id="print">
        <a-row type="flex" justify="start" v-if="!hideDetails">
          <a-col>
            <v-btn class="disabled" text rounded>
              <span v-if="viewedRule.document.MailAddress">
                メール アドレス:
              </span>
              <span v-else> メール アドレス: </span>
            </v-btn>
            <span
              v-if="viewedRule.document.MailAddress"
              v-clipboard="() => viewedRule.document.MailAddress"
              @click="Copy()"
              style="cursor: pointer"
            >
              <a>{{ viewedRule.document.MailAddress.toLowerCase() }} </a>
            </span>
            <span v-else>N/A</span>
            <!-- <v-tooltip top>
                         <template v-slot:activator="{ on }">
                        <v-icon
                        v-on="on"
                      small
                      v-clipboard="() => viewedRule.document.MailAddress"
                      @click="Copy()"
                      >mdi-content-copy</v-icon
                    >
                     </template>
                     <span>Copy mail address to clipboard</span>
                   </v-tooltip> -->
          </a-col>
          <a-col>
            <v-btn rounded text class="disabled">
              開始時期:
              <span v-if="viewedRule.document.BeginningPeriod">
                {{ viewedRule.document.BeginningPeriod }}
              </span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col>
            <v-btn rounded text class="disabled">
              <span v-if="viewedRule.RulebookUpdatedDate">
                ルールブック更新日:
                {{ viewedRule.RulebookUpdatedDate }}
              </span>
              <span v-else> ルールブック更新日: N/A </span>
            </v-btn>
          </a-col>

          <a-col :span="24"><hr /></a-col>
        </a-row>

        <a-row type="flex" justify="start" v-if="!hideDetails">
          <a-col :span="8" :sm="6" :md="4">
            <v-btn rounded text class="disabled">
              <span v-if="viewedRule.productType">
                製品区分&nbsp;-&nbsp;
                {{ viewedRule.productType }}
              </span>
              <span v-else> 製品区分&nbsp;-&nbsp; N/A </span>
            </v-btn>
          </a-col>
          <a-col :span="182" :sm="6" :md="4">
            <v-btn rounded text class="disabled">
              区分&nbsp;-&nbsp;
              <span v-if="viewedRule.criteria">
                {{ viewedRule.criteria }}
              </span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col :span="8" :sm="6" :md="4">
            <v-btn rounded text class="disabled">
              建物構法&nbsp;-&nbsp;
              <span v-if="viewedRule.method">
                <span v-if="viewedRule.method == 'JIKU'">軸組</span>
                <span v-if="viewedRule.method == 'WAKU'">枠組</span>
                <span
                  v-if="
                    viewedRule.method == 'JIKU/WAKU' ||
                      viewedRule.method == 'WAKU/JIKU'
                  "
                  >軸組 枠組</span
                >
              </span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col :span="8" :sm="6" :md="4">
            <v-btn rounded text class="disabled">
              担当&nbsp;-&nbsp;
              <span v-if="viewedRule.document.Tantou">
                {{ viewedRule.document.Tantou }}
              </span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col :span="8" :sm="6" :md="4">
            <v-btn rounded text class="disabled">
              担当 部署:&nbsp;&nbsp;
              <span v-if="viewedRule.document.TantouBusho">{{
                viewedRule.document.TantouBusho
              }}</span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col>
            <v-btn rounded text class="disabled">
              適用区分&nbsp;-&nbsp;
              <span v-if="viewedRule.document.TekiyouKubun">{{
                viewedRule.document.TekiyouKubun
              }}</span>
              <span v-else>N/A</span>
            </v-btn>
          </a-col>
          <a-col :span="24"><hr /></a-col>
        </a-row>
        <!-- ONGOING -->
        <!-- ========================================= -->
        <a-row type="flex" justify="start" v-if="!hideDetails">
          <!-- NOTE: new arrangement of house type 2024-05-16 start -->
          <!-- NOTE: 1st column 2024-05-16 -->
          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.G"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp; グラン・セゾン
              <!-- グランド セゾン  2024-05-16-->
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Gsmart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;
              <!-- GSMART 2024-05-16-->
              グラン・スマート
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- Menshin 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Menshin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <!-- NOTE: 2nd column 2024-05-16 -->
          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.F"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- I-smart 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Ismart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smart
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- 耐震 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Taishin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;耐震
            </v-btn>
          </v-col>

          <!-- NOTE: 3rd column 2024-05-16 -->
          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <!-- セゾンF2-TD 2024-05-16 -->
              <v-icon v-if="viewedRule.document['F2TD']"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF2-TD
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- I-cube 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Icube"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-cube
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- 夢の家 2024-05-16 -->
              <v-icon v-if="viewedRule.document.YumeNoIe"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;夢の家
            </v-btn>
          </v-col>

          <!-- NOTE: 4th column 2024-05-16 -->
          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <!-- セゾンA 2024-05-16 -->
              <v-icon v-if="viewedRule.document.A"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- I-smile 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Ismile"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smile
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- 一般 2024-05-16 -->
              <v-icon v-if="viewedRule.document.Ippan"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;一般
            </v-btn>
          </v-col>

          <!-- NOTE: 5th column 2024-05-16 -->
          <v-col cols="3">
            <v-btn rounded text class="disabled">
              <!-- ブリアール 2024-05-16 -->
              <v-icon v-if="viewedRule.document.B"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;ブリアール
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- HUGme 2024-05-16 -->
              <v-icon v-if="viewedRule.document.HUGme"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <!-- HUGme fam 2024-05-16 -->
              <v-icon v-if="viewedRule.document.HUGmefam"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme fam
            </v-btn>
          </v-col>
          <!-- NOTE: new arrangement of house type 2024-05-16 end -->

          <!-- NOTE: old arrangement of house types 2024-05-16 start -->
          <!-- <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.YumeNoIe"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;夢の家
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document['F2TD']"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF2-TD
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Menshin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Ippan"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;一般
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Icube"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-cube
            </v-btn>

            <br />

            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.HUGme"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.G"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp; グラン・セゾン
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Ismart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smart
            </v-btn>

            <br />

            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.HUGmefam"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;HUGme fam
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.F"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Gsmart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;
              グラン・スマート
            </v-btn>
            <br />

            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Saison"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾン(仮)
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.A"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Ismile"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smile
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Briard"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;ブリアール(仮)
            </v-btn>
          </v-col>

          <v-col cols="2">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.B"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;ブリアール
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Taishin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;耐震
            </v-btn>
          </v-col> -->
          <!-- NOTE: old arrangement of house types 2024-05-16 end -->
        </a-row>

        <!-- <a-row type="flex" justify="start" v-if="!hideDetails">
          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.G"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;グラン・セゾン
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Gsmart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;グラン・スマート
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Menshin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;免震
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.F"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF
            </v-btn>
            <br />
            <v-btn rounded text class="disabled" style="text-transform: lowercase;">
             <v-icon v-if="viewedRule.document.Ismart"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smart
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Taishin"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;耐震
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
             <v-icon v-if="viewedRule.document['F2TD']"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンF2-TD
            </v-btn>
            <br />
            <v-btn rounded text class="disabled" style="text-transform: lowercase;">
              <v-icon v-if="viewedRule.document.Icube"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-cube
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.YumeNoIe"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;夢の家
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.A"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;セゾンA
            </v-btn>
            <br />
            <v-btn rounded text class="disabled" style="text-transform: lowercase;">
             <v-icon v-if="viewedRule.document.Ismile"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;i-smile
            </v-btn>
            <br />
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.Ippan"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;一般
            </v-btn>
          </v-col>

          <v-col cols="2.4">
            <v-btn rounded text class="disabled">
              <v-icon v-if="viewedRule.document.B"
                >mdi-check-circle-outline</v-icon
              >
              <v-icon v-else>mdi-close</v-icon>&nbsp;ブリアール
            </v-btn>
          </v-col> 
        </a-row> -->

        <hr />
        <v-layout pl-4 pt-4>
          <p style="font-size: 20px">件名: &nbsp;</p>
          <p>
            <span
              style="font-size: 20px"
              v-html="viewedRule.document.RuleKenmei"
            >
            </span>
          </p>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="hideDetails = !hideDetails">
            <span v-if="hideDetails"
              >View more details
              <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
            </span>
            <span v-else
              >Hide details <v-icon>mdi-arrow-up-drop-circle-outline</v-icon>
            </span>
          </v-btn>
        </v-layout>
        <hr />

        <v-card
          class="ma-1 pa-1"
          flat
          :height="hideDetails ? screenSize - 142 : screenSize - 350"
          style="overflow-y: scroll"
        >
        <!-- NOTE: new viewing of content 2024-07-23 -->
        <div class="mce-content-body " id="tinymce" data-id="tinymce" aria-label="Rich Text Area. Press ALT-0 for help." contenteditable="false" spellcheck="false" style="font-family:MS Gothic; font-size: 17px;">

          <v-flex
            xs12
            v-for="(item, index) in viewedRule.document.content"
            :key="index"
          >
            <!-- NOTE: added page indication in viewing documents 2024-03-15 -->
            <hr class="noprint" :id="`p${index+1}`" style="border-top:2px dotted gray;">
            <h1 class="noprint" style="font-family: Roboto,sans-serif; font-size:28px; font-weight:500; text-align: center; line-height:0px background-color:white; color:gray;">page {{index+1}}</h1>
            <div v-html="item"></div>
          </v-flex>
        </div>
          <!-- <editor
            :init="tiny_init"
            v-model="mergedContent"
            height="1500"
            id="tinymce_viewing"
          ></editor> -->
        </v-card>


      </v-card>
    </template>
    <v-dialog v-model="historyDialog" persistent max-width="800">
      <v-toolbar dense dark color="#1565c0">
        <v-toolbar-title> History Logs </v-toolbar-title>
        <v-spacer />
        <v-icon @click="historyDialog = false">mdi-close</v-icon>
      </v-toolbar>

      <v-card>
        <v-row class="mr-1 ml-1" dense no-gutters>
          <v-col cols="3" style="text-align: center"> Updated Date </v-col>
          <v-col cols="7" style="text-align: center"> Remarks </v-col>
          <v-col cols="2" style="text-align: center"> Person-in-Charge </v-col>
          <v-col
            cols="12"
            style="text-align:center"
            v-if="historyData.length == 0"
          >
            <h3 style="font-family:Arial;font-weight:bold">No Records Found</h3>
            <!-- 2022-10-18-->
          </v-col>
        </v-row>

        <template v-for="(item, index) in historyData">
          <v-card
            @click="
              historyIndex = index;
              viewHistoryIndex(index);
            "
            :class="listColorSearch(index)"
            style="cursor: pointer"
            :key="index"
            flat
          >
            <v-layout>
              <v-flex xs12 s6 md3 class="pl-3">
                {{ index + 1 }}. &nbsp;
                {{ item.updatedDate }}
              </v-flex>
              <v-flex xs12 s6 md7>
                <div style="word-wrap: break-word">
                  {{ item.updateContents }}
                </div>
              </v-flex>
              <v-flex xs12 s6 md2 style="text-align: center">
                {{ item.personIncharge }}
              </v-flex>
            </v-layout>
          </v-card>
        </template>
      </v-card>
    </v-dialog>
  <v-overlay :value="downloading">
    <v-container
      class="d-flex flex-column align-center justify-center"
      fill-height
    >
      <v-progress-circular
        :size="64"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      <div class="overlay-text">Downloading... This may take a few moments, please wait. <br>ダウンロード中です... 少々お待ちください。</div>
    </v-container>
  </v-overlay>
  </v-container>
</template>
<script>
import axios from "axios";
import AWS from "aws-sdk";
import tunnel from "tunnel";
import config from "../config";
import * as binconv from "binconv";
import FileSaver from "file-saver";
import converter from "base64-arraybuffer";
import Swal from "sweetalert2";
import _ from "lodash";

// import Editor from "@tinymce/tinymce-vue";
// import tinymce from "tinymce/tinymce";
// import contentUiCss from "!!raw-loader!tinymce/skins/ui/oxide/content.min.css";

// import "tinymce/tinymce";
// import "tinymce/themes/silver";
// import "tinymce/icons/default";
// import "tinymce/skins/ui/oxide/skin.css";
// import moji from "moji";
export default {
  title() {
    if (this.$route.query.title) {
      return `@Rulebook ${this.$route.query.title
        .replace(/(<([^>]+)>)/gi, "")
        .replace(/&nbsp;/gi, "")}`;
    }
  },
  name: "newPage",
  props: ["search"],
  // components: {
  //   editor: Editor,
  // },
  data() {
    return {
      // tiny_init: {
      //   // Tiny Configuration for Viewing
      //   object_resizing: false,
      //   inline: true,
      //   readonly: 1,
      //   toolbar: false,
      //   visual: false,
      //   menubar: false,
      //   skin: false,
      //   content_css: false,
      //   content_style:
      //     [contentUiCss].join("\n") +
      //     "body { font-family:Times New Roman,Arial,sans-serif}",
      //   init_instance_callback : function(editor)
      //   {
      //     editor.getBody().setAttribute('contenteditable',false);
      //   }
      // },
      downloading: false,
      hideDetails: true,
      isFavorite: false,
      loading: false,
      LINKSSSS: "",
      pdfNo: "",
      title_spacer: "　",
      viewedRule: {
        approver: "",
        criteria: "",
        document: {
          SeihinKubun: "",
          KanriBangou: "",
          RuleKenmei: "",
          Kubun: "",
          YumeNoIe: true,
          Ippan: true,
          Icube: false,
          Ismart: false,
          Taishin: true,
          Menshin: true,
          Tantou: "",
          MailAddress: "",
          TantouBusho: "",
          TekiyouKubun: "",
          UpdatedDate: "",
        },
        documentType: "RULEBOOK",
        isApproved: true,
        method: "JIKU",
        productType: "外部付帯",
        updated: true,
        version: 1,
        _id: "",
        _rev: "0",
      },
      viewedRule2: {
        approver: "",
        criteria: "",
        document: {
          content: "",
          SeihinKubun: "",
          KanriBangou: "",
          RuleKenmei: "",
          Kubun: "",
          YumeNoIe: true,
          Ippan: true,
          Icube: false,
          Ismart: false,
          Taishin: true,
          Menshin: true,
          Tantou: "",
          MailAddress: "",
          TantouBusho: "",
          TekiyouKubun: "",
          UpdatedDate: "",
        },
        documentType: "RULEBOOK",
        isApproved: true,
        method: "JIKU",
        productType: "外部付帯",
        updated: true,
        version: 1,
        _id: "",
        _rev: "0",
      },
      docTitle: "",
      historyData: [],
      historyDialog: false,
      historyIndex: 0,
      linkIsNotExist: false,
    };
  },

  async created() {
    // console.log(this.breakpointHeight)
    //20230905 is salesuser
    if (this.userInfo.isSalesUser) {
      // console.log(this.userInfo.isSalesUser);
      Swal.fire({
        title: "Sorry, cannot open document due to Users Restriction!",
        // text: "Sorry Cannot Open Document Due to User Restrictions",
        icon: "error",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "I got it",
      }).then((result) => {
        if (result.isConfirmed) {
          window.close();
        }
      });
    } else {
      this.hideDetails = false; // 2022-03-07 Auto Open View More Details

      window.FileSrc = this.FileSrc.bind(this);
      this.checkFavorite();
      let url = "";
      if (this.$route.params.id.length == 13) {
        this.$route.params.id = this.$route.params.id.substring(0, 9);
        // console.log(this.$route.params.id)
      }
      if (this.$route.query.search && !this.$route.query.multi) {
        this.loading = true;
        //and or search
        url = `${this.api}get/morethanthis/test/${this.$route.params.id}?search=${this.$route.query.search}`;
      } else if (this.$route.query.search && this.$route.query.multi) {
        this.loading = true;
        //multi
        url = `${this.api}get/morethanthis/multiple/${this.$route.params.id}?search=${this.$route.query.search}`;
      } else {
        this.loading = true;
        //simple search
        url = `${this.api}get/this/${this.$route.params.id}`;
      }
      // this.ReplaceImageFromS3(url)
      this.LoadingImage(url);
      // console.log(url);
    }
    // console.log(this.$route.query.search);
    // console.log(this.$route.params.id);
  },
  methods: {
    // getImageUrl(imgName){
    //   return new Promise((resolve) => {
    //     var tunnelingAgent = tunnel.httpsOverHttp({
    //       proxy: {
    //         host: "hrdproxy.hrd-s.com",
    //         port: 81,
    //         proxyAuth: "administrator:system",
    //       },
    //     });

    //     AWS.config.update({
    //       httpOptions: { agent: tunnelingAgent },
    //       region: "us-east-2",
    //       credentials: config,
    //     });
    //     var s3 = new AWS.S3({
    //       AccessKeyID: this.accessKeyId,
    //       SecretAccessKey: this.secretAccessKey,
    //       Region: "us-east-2",
    //       params: {
    //         Bucket: "rulebook.files",
    //         Key: `Document_images/${imgName}`,
    //         Expires: 100
    //       },
    //     });
    //     const url = s3.getSignedUrl('getObject');
    //     console.log(url)
    //     resolve(url)
    //   });
    // },
    // 2022-10-18
    async viewHistory(id) {
      try {
        let url = `${this.api}rulebookHistory/GetSpecificRuleBookHistory/${id}`;
        let response = await axios.get(url);
        this.historyData = _.orderBy(response.data, ["updatedDate"], ["desc"]);
        // console.log("line627", this.historyData);
        this.historyDialog = true;
      } catch (err) {
        console.log(err.message);
        alert("Error Fetching Records");
      }
    },
    obsolete(val) {
      let url = `${this.api}obsoleteDocument?id=${val}`;
      Swal.fire({
        title: `Obsolete document ${val} ?`,
        icon: "info",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: `YES!`,
      }).then((result) => {
        if (result.value) {
          axios.defaults.headers.common["x-api-key"] =
            "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
          axios.get(url).then((res) => {
            let title = "";
            if (res.data == "OK") {
              title = "Success!";
            } else {
              title = "Error!";
            }
            Swal.fire({
              icon: "success",
              title: title,
              showConfirmButton: false,
              timer: 750,
            });
          });
        }
      });
    },
    listColorSearch(id) {
      if (this.historyIndex == id) {
        return "viewed";
      }
    },
    viewHistoryIndex(index) {
      console.log(index);
      //let url = `http://hrdapps59:2429/get/history/index/${this.$route.params.id}?index=${index}`;
      // this.LoadingImage(url);
    },
    viewHistory2(id) {
      // console.log(id);
      let url = `${this.api}get/history/${id}`;
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then((res) => {
        // console.log("history",res.data)
        this.historyData = res.data.history;
        this.historyDialog = true;
      });
    },
    // NOTE: storing of rulebook 2024-06-21
    goToFeedback() {
      if (this.$router.currentRoute.name != "feedback") {
        if (!Array.isArray(this.$store.state.shiyoObjectFeedBack)) {
          this.$store.state.shiyoObjectFeedBack.documentType = 'ruleBook';
        } 
        this.$store.commit('CHANGE_SHIYO_FEEDBACK', this.$store.state.shiyoObjectFeedBack);
        console.log("test", this.$store.state.shiyoObjectFeedBack.documentType);
        window.open(
          `/feedback?id=${this.viewedRule._id}`,
          "_blank"
        );
      }
    },
    PDFSrc() {
      let controlNo = this.$route.params.id.substring(0, 9);
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      // console.log(tunnelingAgent, "proxy");

      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      });
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });

      var options = {
        Bucket: "rulebook.files",
        Key: `PDF_Files/${controlNo}.pdf`,
      };
      // console.log(s3, "s3");

      s3.getObject(options, function(err, data) {
        if (err) console.log(err, err.stack);
        // an error occurred
        else {
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
          // console.log(data);

          // console.log(this.viewedRule)
          FileSaver.saveAs(blob, `${controlNo}.pdf`);
          // successful response
        }
      });
      // alert('download complete')
    },
    //ongoing
    LoadingImage(url) {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      axios.get(url).then(async (res) => {
        // Change Tab Name ( 2022-03-05 )
        document.title = `${res.data._id}-${res.data.document.RuleKenmei}`;

        let content = [];
        content = res.data.document.content;
        for (let i = 0; i < content.length; i++) {
          let indices = [];
          let imgName = [];

          let findStr = new RegExp(
            // "http://hrdapps48:5010/uploads/image/froala/test/",
            "http://10.168.64.31:5010/uploads/image/froala/test/|http://hrdapps48:5010/uploads/image/froala/test/|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/|http:\\\\\\\\10.11.1.59:2929\\\\rulebook_img\\\\",
            "g"
          );

          let result = "";
          while ((result = findStr.exec(content[i]))) {
            // indices.push(result.index);
            indices.push([result.index,result[0]]);
          }

          // if (indices.length > 0) {
          //   imgName = indices.map((index) => {
          //     return content[i].substring(index + 48, index + 48 + 44);
          //   });
          // }

          if (indices.length > 0) {
            let findExt = new RegExp(".png|.jpg", "ig")
            imgName = indices.map((item) => {
                let end = findExt.exec(content[i],item[0]).index + 4
                // return str.substring(index + 48, index + 48 + 44);
                return content[i].substring(item[0] + item[1].length, end);
            });
          }
          
          // console.log(imgName)
          for (let j = 0; j < imgName.length; j++) {
            // console.log(`replace${j}`)
            let deleteSrc = new RegExp(
              // `src="http://hrdapps48:5010/uploads/image/froala/test/${imgName[j]}"`
              `src="http://10.168.64.31:5010/uploads/image/froala/test/${imgName[j]}"|src="http://hrdapps48:5010/uploads/image/froala/test/${imgName[j]}"|src="https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/${imgName[j]}"|src="http:\\\\\\\\10.11.1.59:2929\\\\rulebook_img\\\\${imgName}"`
            );
            content[i] = content[i].replace(
              deleteSrc,
              `<img src='../loading.gif' height="auto" width="auto"`
            );
          }
          res.data.document.content[i] = content[i];
        }
        this.viewedRule2 = res.data;
        let findXLS = new RegExp(".xls|xlsx", "g");
        let findDXF = new RegExp(".dxf", "g");
        if (
          this.viewedRule2.document.content.toString().match(findXLS) != null
        ) {
          // console.log("xls");
          this.viewedRule2 = await res.data;
          await this.replaceLink();
        } else if (
          this.viewedRule2.document.content.toString().match(findDXF) != null
        ) {
          // console.log("dxf");
          this.viewedRule2 = await res.data;
          await this.replaceLink();
        } else {
          // console.log("not xls and dxf - pdf");
          this.viewedRule2 = res.data;
          await this.replaceLink();
        }

        this.loading = false;
        this.ReplaceImageFromS3(url);
      })
      .catch(err=>{
        if(err){
          this.linkIsNotExist = true
        }
      });
    },
    async ReplaceImageFromS3(url) {
      //ongoing
      let findXLS = new RegExp(".xls|xlsx", "g");
      let findDXF = new RegExp(".dxf", "g");

      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      await axios.get(url).then(async (res) => {
        let data = res.data.document.content;

        const strToBase64 = (str) => {
          let indices = [];
          let imgName = [];

          let findStr = new RegExp(
            "http://10.168.64.31:5010/uploads/image/froala/test/|http://hrdapps48:5010/uploads/image/froala/test/|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/",
            "g"
          );

          let result = "";
          while ((result = findStr.exec(str))) {
            indices.push([result.index,result[0]]);
          }
          let findExt = new RegExp(".png|.jpg", "ig")
          if (indices.length > 0) {
            imgName = indices.map((item) => {
                let end = findExt.exec(str,item[0]).index + 4
                // return str.substring(index + 48, index + 48 + 44);
                return str.substring(item[0] + item[1].length, end);
            });
          }
          return imgName;
        };
        // ADDED 20231201
        //Code for alignment of image
        //commented on 20240111
        // for (let x in data) {
        //   let sentence = data[x];
        //   var outputString = sentence.replace(
        //     /\bclass\s*=\s*"[^"]*fr[^"]*"\s*/g,
        //     ""
        //   );
        //   data[x] = outputString;
        //   if (data[x].includes("Excel_files")) {
        //     console.log(data[x]);
        //   }
        // }
        let s3Keys = data.map((rec) => {
          return strToBase64(rec);
        });

        s3Keys = [].concat.apply([], s3Keys);
        let promises = [];
        if (s3Keys.length > 0) {
          for (let i = 0; i < s3Keys.length; i++) {
            promises.push(this.ImageSrc(s3Keys[i]));
          }
          let newContent = res.data.document.content.join("-----");
          Promise.all(promises).then((datas) => {
            // console.log('datasssssssss',data)
            // console.log('count',datas.length)
            for (let i = 0; i < datas.length; i++) {
              let toReplace = new RegExp(
                `http://10.168.64.31:5010/uploads/image/froala/test/${s3Keys[i]}|http://hrdapps48:5010/uploads/image/froala/test/${s3Keys[i]}|https://s3.us-east-2.amazonaws.com/rulebook.files/Document_images/${s3Keys[i]}`,
                "g"
              );
              newContent = newContent.replace(toReplace, datas[i]);
            }
            // console.log("Here New", newContent);
            res.data.document.content = newContent.split("-----");
            if (res.data.document.content.toString().match(findXLS) != null) {
              // console.log("xls2");
              this.viewedRule2 = res.data;
              this.replaceLink();
            } else if (
              res.data.document.content.toString().match(findDXF) != null
            ) {
              // console.log("dxf 2");
              this.viewedRule2 = res.data;
              this.replaceLink();
            } else {
              // console.log("pdf");
              // console.log(this.viewedRule2);
              this.viewedRule2 = res.data;

              this.replaceLink(); ////added 20210730
            }

            this.loading = false;
          });
        } else {
          // console.log('no Image', res.data.document)
          // this.viewedRule2 = res.data;
          if (res.data.document.content.toString().match(findXLS) != null) {
            // console.log('xls')
            this.viewedRule2 = res.data;
            this.replaceLink();
          } else if (
            res.data.document.content.toString().match(findDXF) != null
          ) {
            // console.log("dxf 3");
            this.viewedRule2 = res.data;
            this.replaceLink();
          } else {
            // console.log("not dxf 3");
            this.viewedRule2 = res.data;
            this.replaceLink();
          }
          this.loading = false;
        }
        // }
      });
      // NOTE: insert border in table 2024-07-29
      setTimeout(() => {
        let tables = document.querySelector('#tinymce').querySelectorAll('table')
        // console.log('tiny',tables)
        for(let table of tables){
          let className = table.className
          if(!className.match(/alignPatternTable|MyHeader/ig)){
            table.setAttribute('border','1')
          }
        }
      }, 500);
      // await this.replaceLink()
    },
    replaceLink() {
      //ongoing link
      let findXLS = new RegExp(".xls|.xlsx", "g");
      let findDXF = new RegExp(".dxf", "g");

      if (this.viewedRule2.document.content.toString().match(findXLS) != null) {
        // console.log("line904 >> CS1");
        for (let j = 0; j < this.viewedRule2.document.content.length; j++) {
          let findLink = new RegExp(
            `href="http://hrdapps48:3001/ftp/rule_book/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/PDF_Files/`,
            "g"
          );
          let indicesLink = [];
          let indicesXLS = [];
          let indicesXLS2 = [];
          let Newfunction = [];
          let result = "";
          while (
            (result = findLink.exec(this.viewedRule2.document.content[j]))
          ) {
            indicesLink.push(result.index);
          }
          while (
            (result = findXLS.exec(this.viewedRule2.document.content[j]))
          ) {
            if (result[0] == ".xls" || result[0] == ".xlsx") {
              indicesXLS.push(result.index);
            }
          }
          for (let i = 0; i < indicesLink.length; i++) {
            let contentLink = this.viewedRule2.document.content[j];
            this.viewedRule2.document.content[j] = contentLink.replace(
              findLink,
              function() {
                // return 'href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/'
                return `onclick="FileSrc('`;
                //  return `href="FileSrc('`;
              }
            );
          }
          while (
            (result = findXLS.exec(this.viewedRule2.document.content[j]))
          ) {
            if (result[0] == ".xls" || result[0] == ".xlsx") {
              indicesXLS2.push(result.index);
            }
          }

          let findFunction = new RegExp("onclick", "g");
          while (
            (result = findFunction.exec(this.viewedRule2.document.content[j]))
          ) {
            Newfunction.push(result.index);
          }
          let attribs = new RegExp(
            `rel="noopener noreferrer" target="_blank"`,
            "g"
          );
          this.viewedRule2.document.content[
            j
          ] = this.viewedRule2.document.content[j]
            .replace(/.xls|.xlsx/g, function(str) {
              if (str == ".xls") {
                // 2024-02-12 commented because attached excel file can't download
                return `${str}')`;
                // return `${str}`;
              } else {
                return str;
              }
            })
            .replace(/.xls'[)]x/g, ".xlsx')")
            .replace(attribs, "");
          let tempContent = this.viewedRule2.document.content[j];
          this.viewedRule2.document.content[
            j
          ] = this.viewedRule2.document.content[j]
            .replace(/.pdf/g, function(str, index) {
              if (tempContent.substring(index, index - 50).match(/https:/)) {
                return str;
              } else {
                if (str == ".pdf") {
                  // console.log("line974 tanskie ");
                  return `${str}')`; // 2022-02-11
                } else return str;
              }
            })
            .replace(attribs, "");
        }

        // this.viewedRule = this.viewedRule2; // tanskie ( 2022-01-28 ) old

        let newSetArray = []; // new 2022-01-28
        this.viewedRule2.document.content.forEach((rec) => {
          if (
            rec.includes(
              "禁止" ||
                "計画禁止" ||
                "設置禁止" ||
                "できない" ||
                "不可" ||
                "計画不可" ||
                "設置不可"
            )
          ) {
            let toPush = "";
            toPush = rec
              .replace(
                /計画禁止/g,
                '<span style="color:red; font-weight: 700">計画禁止</span>'
              )
              .replace(
                /設置禁止/g,
                '<span style="color:red; font-weight: 700">設置禁止</span>'
              )
              .replace(
                /禁止/g,
                '<span style="color:red; font-weight: 700">禁止</span>'
              )
              .replace(
                /できない/g,
                '<span style="color:red; font-weight: 700">できない</span>'
              )
              .replace(
                /計画不可/g,
                '<span style="color:red; font-weight: 700">計画不可</span>'
              )
              .replace(
                /設置不可/g,
                '<span style="color:red; font-weight: 700">設置不可</span>'
              )
              .replace(
                /不可/g,
                '<span style="color:red; font-weight: 700">不可</span>'
              );
            newSetArray.push(toPush);
          } else {
            newSetArray.push(rec);
          }
        });

        this.viewedRule2.document.content = newSetArray;
        this.viewedRule = this.viewedRule2;
      } else if (
        this.viewedRule2.document.content.toString().match(findDXF) != null
      ) {
        ////DXF////
        // console.log("line1012 >> CS2");
        // console.log("DXF REPLACE LINK");
        for (let j = 0; j < this.viewedRule2.document.content.length; j++) {
          let findLink = new RegExp(
            `href="http://hrdapps48:3001/ftp/rule_book/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/PDF_Files/`,
            "g"
          );
          let indicesLink = [];
          let indicesXLS = [];
          let indicesXLS2 = [];
          let Newfunction = [];
          let result = "";
          while (
            (result = findLink.exec(this.viewedRule2.document.content[j]))
          ) {
            indicesLink.push(result.index);
          }

          while (
            (result = findDXF.exec(this.viewedRule2.document.content[j]))
          ) {
            if (result[0] == ".dxf") {
              indicesXLS.push(result.index);
            }
          }
          for (let i = 0; i < indicesLink.length; i++) {
            let contentLink = this.viewedRule2.document.content[j];
            this.viewedRule2.document.content[j] = contentLink.replace(
              findLink,
              function() {
                // return 'href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/'
                return `onclick="FileSrc('`;
              }
            );
          }
          while (
            (result = findDXF.exec(this.viewedRule2.document.content[j]))
          ) {
            if (result[0] == ".xls" || result[0] == ".xlsx") {
              indicesXLS2.push(result.index);
            }
          }
          let findFunction = new RegExp("onclick", "g");
          while (
            (result = findFunction.exec(this.viewedRule2.document.content[j]))
          ) {
            Newfunction.push(result.index);
          }
          // console.log(Newfunction)
          let attribs = new RegExp(
            `rel="noopener noreferrer" target="_blank"`,
            "g"
          );
          this.viewedRule2.document.content[
            j
          ] = this.viewedRule2.document.content[j]
            .replace(/.dxf/g, function(str) {
              if (str == ".dxf") {
                return `${str}')`;
                // return `${str}`;
              } else {
                return str;
              }
            })
            .replace(attribs, "");
          let tempContent = this.viewedRule2.document.content[j];
          this.viewedRule2.document.content[
            j
          ] = this.viewedRule2.document.content[j]
            .replace(/.pdf/g, function(str, index) {
              if (tempContent.substring(index, index - 50).match(/https:/)) {
                return str;
              } else {
                if (str == ".pdf") {
                  return `${str}')`;
                } else return str;
              }
            })
            .replace(attribs, "");
        }
        this.viewedRule = this.viewedRule2;
      } else {
        // console.log("line1092 >> CS3");
        for (let j = 0; j < this.viewedRule2.document.content.length; j++) {
          let findLink = new RegExp(
            `href="http://hrdapps48:3001/ftp/rule_book/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/Excel_files/|href="https://s3.us-east-2.amazonaws.com/rulebook.files/PDF_Files/`,
            "g"
          );
          let indicesLink = [];
          let Newfunction = [];
          let result = "";
          while (
            (result = findLink.exec(this.viewedRule2.document.content[j]))
          ) {
            indicesLink.push(result.index);
          }

          for (let i = 0; i < indicesLink.length; i++) {
            let contentLink = this.viewedRule2.document.content[j];
            this.viewedRule2.document.content[j] = contentLink.replace(
              findLink,
              function() {
                // return 'href="https://s3.us-east-2.amazonaws.com/rulebook.files/rulebook_attachment/'
                return `onclick="FileSrc('`;
              }
            );
          }

          let findFunction = new RegExp("onclick", "g");
          while (
            (result = findFunction.exec(this.viewedRule2.document.content[j]))
          ) {
            Newfunction.push(result.index);
          }
          // console.log(Newfunction)
          let attribs = new RegExp(
            `rel="noopener noreferrer" target="_blank"`,
            "g"
          );
          let tempContent = this.viewedRule2.document.content[j];
          this.viewedRule2.document.content[
            j
          ] = this.viewedRule2.document.content[j]
            .replace(/.pdf/g, function(str, index) {
              if (tempContent.substring(index, index - 50).match(/https:/)) {
                // console.log("aaaaaaaaa", str);
                return str;
              } else {
                if (str == ".pdf") {
                  // console.log('line1139', tempContent );
                  // console.log("line1137", typeof tempContent);
                  // console.log("bbbbbb", `${str}'`);

                  return `${str}')`;
                } else {
                  // console.log("ccccccccc", str);

                  return str;
                }
              }
            })
            .replace(attribs, "");
        }
        // this.viewedRule = this.viewedRule2; // tanskie ( 2022-01-28 ) old

        let newSetArray = []; // new 2022-01-28
        this.viewedRule2.document.content.forEach((rec) => {
          if (
            rec.includes(
              "禁止" ||
                "計画禁止" ||
                "設置禁止" ||
                "できない" ||
                "不可" ||
                "計画不可" ||
                "設置不可"
            )
          ) {
            let toPush = "";
            toPush = rec
              .replace(
                /計画禁止/g,
                '<span style="color:red; font-weight: 700">計画禁止</span>'
              )
              .replace(
                /設置禁止/g,
                '<span style="color:red; font-weight: 700">設置禁止</span>'
              )
              .replace(
                /禁止/g,
                '<span style="color:red; font-weight: 700">禁止</span>'
              )
              .replace(
                /できない/g,
                '<span style="color:red; font-weight: 700">できない</span>'
              )
              .replace(
                /計画不可/g,
                '<span style="color:red; font-weight: 700">計画不可</span>'
              )
              .replace(
                /設置不可/g,
                '<span style="color:red; font-weight: 700">設置不可</span>'
              )
              .replace(
                /不可/g,
                '<span style="color:red; font-weight: 700">不可</span>'
              );
            newSetArray.push(toPush);
          } else {
            newSetArray.push(rec);
          }
        });
        //NOTE: add modification for page links 2024-06-03
        this.viewedRule2.document.content = newSetArray;
        this.viewedRule = this.viewedRule2;

        // console.log('HERE',this.viewedRule)
      }
    },
    FileSrc(filename) {
      if(filename.match(/.pdf/ig)){
        // console.log(filename)
      }
      var tunnelingAgent = tunnel.httpsOverHttp({
        proxy: {
          // Proxy settings
          host: "hrdproxy.hrd-s.com",
          port: 81,
          proxyAuth: "administrator:system",
        },
      });
      // console.log(tunnelingAgent, "proxy");
      AWS.config.update({
        httpOptions: { agent: tunnelingAgent },
        region: "us-east-2",
        credentials: config,
      }); 
      var s3 = new AWS.S3({
        AccessKeyID: this.accessKeyId,
        SecretAccessKey: this.secretAccessKey,
        Region: "us-east-2",
        params: {
          bucket: "rulebook.files",
        },
      });
      var options = {
        Bucket: "rulebook.files",
        // Key: `Excel_files/${filename}`,
        Key: `rulebook_attachment/${filename}`,
      };
      // console.log(s3, "s3");

      //START OF DOWNLOAD FILE//
      var self = this;
      self.downloading = true;
      s3.getObject(options, function(err, data) {
        if (err) {
          self.downloading = false;
          console.log(err, err.stack);}
        // an error occurred
        else {
          // console.log(data);
          var blob = new Blob([binconv.uint8ArrayToBlob(data.Body)], {
            type: `${data.ContentType};charset=utf-8`,
          });
      //success download finish//
          FileSaver.saveAs(blob, `${filename}`);
          self.downloading = false;
          Swal.fire({
          position: "center",
          icon: "success",
          title: "Your file has been successfully downloaded.<br>ファイルが正常にダウンロードされました。",
          showConfirmButton: false,
          timer: 2000
        });
        } // successful response 
      });
    },
    checkFavorite() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let toCheck = {
        id: this.$route.params.id.substring(0, 9),
        userId: this.userInfo.id,
      };
      let url = `${this.api}checkFavorite`;
      axios.post(url, toCheck).then((res) => {
        this.isFavorite = res.data;
      });
    },
    addFavorite() {
      axios.defaults.headers.common["x-api-key"] =
        "H8Pt5n6cbFk95UiXQjwZ9cwu6mcZqfF2rTw3HeS3";
      let toInsert = {
        id: this.$route.params.id.substring(0, 9),
        userId: this.userInfo.id,
        isFavorite: this.isFavorite,
      };
      // console.log(toInsert)
      let url = `${this.api}addFavorite`;
      let url2 = `${this.api}addFavoriteCount`;
      axios.post(url2, toInsert).then(() => {
        axios.post(url, toInsert).then((res) => {
          // console.log(res.data)
          if (res.data == "Add Favorite") {
            this.SET_FAVORITE(this.isFavCnt + 1);
            Swal.fire({
              icon: "success",
              title: `${this.$route.params.id.substring(
                0,
                9
              )} added to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          } else {
            this.SET_FAVORITE(this.isFavCnt - 1);
            Swal.fire({
              icon: "error",
              title: `${this.$route.params.id.substring(
                0,
                9
              )} deleted to Favorites`,
              showConfirmButton: false,
              timer: 1200,
            });
          }
        });
      });
    },
    ListResponsive() {
      let screen = eval(`${window.screen.height} * ${window.devicePixelRatio}`);
      // alert(screen)
      if (screen == "768") {
        return "responsive768";
      } else if (screen == "1024") {
        return "responsive1024";
      } else if (screen == "1080") {
        return "responsive1080";
      } else if (screen >= "1200") {
        return "responsive1200";
      } else {
        return "responsive";
      }
    },
    ImageSrc(imgName) {
      // console.log(imgName)
      return new Promise((resolve) => {
        var tunnelingAgent = tunnel.httpsOverHttp({
          proxy: {
            // Proxy settings
            host: "hrdproxy.hrd-s.com",
            port: 81,
            proxyAuth: "administrator:system",
          },
        });
        // console.log(tunnelingAgent, "proxy");

        AWS.config.update({
          httpOptions: { agent: tunnelingAgent },
          region: "us-east-2",
          credentials: config,
        });
        var s3 = new AWS.S3({
          AccessKeyID: this.accessKeyId,
          SecretAccessKey: this.secretAccessKey,
          Region: "us-east-2",
          params: {
            bucket: "rulebook.files",
          },
          // endpoint:'https://s3.us-east-2.amazonaws.com/rulebook.files/'
        });

        var options = {
          Bucket: "rulebook.files",
          Key: `Document_images/${imgName}`,
          // Key: "JWW_Files/02.jww",
          // Key: "9557811-2019-0905D07-Advance_Series__INDIVIDUAL_.xls",
        };
        // console.log(options, "options");
        s3.getObject(options, function(err, data) {
          if (err) {
            console.log(err, err.stack);
            // return false;
          }
          // an error occurred
          else {
            // console.log(data, "s3Data");

            // const base64 = binconv.uint8ArrayToBase64(data.Body);
            const base64 = converter.encode(data.Body);
            let content = `data:${data.ContentType};base64,`;
            // console.log(content);
            // console.log("ConvertBase64", base64);
            let finalcontent = `${content}${base64}`;
            // console.log(finalcontent);
            // var reader = new FileReader();

            // reader.readAsDataURL(base64);
            this.imagesrc = finalcontent;
            // successful response
            // console.log(err);
            resolve(this.imagesrc);
          }
        });
      });
    },
    CloseTab() {
      window.close();
    },
    print() {
      // this.$htmlToPaper("print");
      window.print();
    },
    Copy() {
      Swal.fire({
        // position: "bottom-end",
        icon: "success",
        title: "Copy mail address to clipboard",
        showConfirmButton: false,
        timer: 800,
      });
    },
  },
  computed: {
    mergedContent(){
      if(typeof(this.viewedRule.document.content)=='object'){
        return this.viewedRule.document.content.map((r,i)=>{
            return `<hr class="noprint" id="p${i+1}" style="border-top:2px dotted gray;"><h1 class="noprint" style="text-align: center; line-height:0px background-color:white; color:gray; font-family: Roboto, sans-serif;">page ${i+1}</h1>` + r
          }).join('')
      }else{
        return this.viewedRule.document.content
      }
    },
    isFav() {
      if (this.isFavorite) {
        return "mdi-star";
      } else {
        return "mdi-star-outline";
      }
    },
    screenSize() {
      return this.$vuetify.breakpoint.height;
    },
  },
};
</script>
<style scoped>
#hover:hover {
  background-color: #daf5b3;
}

.viewed {
  background-color: #daf5b3;
}
.theme--light.v-card > .v-card__text {
  font-size: 18px;
}
#responsive {
  max-height: 80vh;
}
#responsive768 {
  max-height: 88vh;
}
#responsive1024 {
  max-height: 92vh;
}
#responsive1080 {
  max-height: 93vh;
}
#responsive1200 {
  max-height: 100vh;
}
a:hover {
  background-color: #e8e8e8;
}
.disabled {
  pointer-events: none;
  text-transform: none;
}
.overlay-text {
  margin-top: 16px;
  color: white;
  font-size: 18px;
  text-align: center;
}
/* .FontStyle{
    font-family: "MS PGothic", Osaka, Arial, sans-serif !important;
  font-size: 18px !important;
} */
@media only print {
  body {
    position: static;
  }

  div.v-toolbar * {
    display: none !important;
  }
  div.v-toolbar__content * {
    display: none !important;
  }
  nav.v-toolbar.elevation-0.theme--light {
    display: none !important;
  }

  div.ant-tabs-nav-scroll * {
    display: none !important;
  }
  div.v-card.v-card--flat.v-sheet.theme--light {
    margin: 0mm;
    padding: 0mm;
    overflow-y: visible !important;
    position: relative;
  }
  .noprint{
    display:none;
  }
  
}
</style>
